import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileStatsProfileRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_stats_and_profile'

  queryParams = {
    partner_id: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    isBonus: {
      refreshModel: true
    }
  };

  model(params) {
    return {...this.paramsFor('risk-management.players.profile'), ...params};
  }

  activate() {
    this.partner.showIsBonusSwitcher = true;
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }
}
