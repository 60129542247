import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  type=\"text\"\n  class={{@class}}\n  value={{@value}}\n  placeholder={{@placeholder}}\n  {{did-insert this.didInsertDateRangePicker}}\n/>\n", {"contents":"<input\n  type=\"text\"\n  class={{@class}}\n  value={{@value}}\n  placeholder={{@placeholder}}\n  {{did-insert this.didInsertDateRangePicker}}\n/>\n","moduleName":"backoffice/components/my-date-range-picker/sb-my-date-range-picker.hbs","parseOptions":{"srcName":"backoffice/components/my-date-range-picker/sb-my-date-range-picker.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment';
import $ from 'jquery';

export default class MyDateRangePickerSbMyDateRangePickerComponent extends Component {
  @service intl;

  ranges = {
    [this.intl.t('Today')]: [moment(), moment()],
    [this.intl.t('Yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    [this.intl.t('Last 7 Days')]: [moment().subtract(6, 'days'), moment()],
    [this.intl.t('Last 30 Days')]: [moment().subtract(29, 'days'), moment()],
    [this.intl.t('This Month')]: [moment().startOf('month'), moment().endOf('month')],
    [this.intl.t('Last Month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  @action
  didInsertDateRangePicker(el) {
    $(el).daterangepicker({
      singleDatePicker: this.args.singleDatePicker,
      timePicker: this.args.timePicker,
      showDropdowns: this.args.showDropdowns,
      showWeekNumbers: this.args.showWeekNumbers,
      timePicker24Hour: this.args.timePicker24Hour,
      autoApply: this.args.autoApply,
      isInvalidDate: this.args.isInvalidDate,
      alwaysShowCalendars: this.args.alwaysShowCalendars,
      startDate: moment(this.args.start),
      endDate: moment(this.args.end),
      opens: this.args.opens,
      minDate: this.args.minDate,
      maxDate: this.args.maxDate,
      ranges: !this.args.hideRanges && this.ranges,
      drops: this.args.drops ?? 'auto',
      showCustomRangeLabel: this.args.showCustomRangeLabel,
      autoUpdateInput: this.args.autoUpdateInput,
      locale: {
        customRangeLabel: this.intl.t('Custom Range'),
        applyLabel: this.intl.t('Apply'),
        cancelLabel: this.args.cancelLabel || this.intl.t('Cancel'),
        format: this.args.format,
      },
    });

    $(el).on('hide.daterangepicker', this.args.hideAction);
    $(el).on('apply.daterangepicker', this.args.applyAction);
    $(el).on('cancel.daterangepicker', this.args.cancelAction);
  }
}
