import AuthenticatedRoute from '../authenticated';
import { inject as service } from '@ember/service';

export default class SettingsDepositButtonsRoute extends AuthenticatedRoute {
  @service navigation;
  @service intl;

  definer = 'settings_deposit_buttons';

  activate() {
    this.navigation.customHeaderTitle = this.intl.t('Deposit buttons configuration');
    this.navigation.isShowIcon = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showPartnerSelect = false;
  }

  deactivate() {
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showPartnerSelect = false;
  }
}
