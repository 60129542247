import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<img\n  style={{html-safe this.style}}\n  onclick={{action this.show}}\n  src={{or @imageUrl '/assets/images/default.jpg'}}\n/>", {"contents":"<img\n  style={{html-safe this.style}}\n  onclick={{action this.show}}\n  src={{or @imageUrl '/assets/images/default.jpg'}}\n/>","moduleName":"backoffice/components/generic-components/file-manager-btn/sb-file-manager-btn.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/file-manager-btn/sb-file-manager-btn.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

export default class GenericComponentsFileManagerBtnSbFileManagerBtnComponent extends Component {
  @service modalWindow;

  @action
  show() {
    this.modalWindow.showFileManager({setImageUrl: this.args.setImageUrl});
  }

  get style() {
    return `width: ${this.args.width || "80px"}; height: ${this.args.height || this.args.width || "80px"}`;
  }
}
