import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/risk-management/players/profile/sb-profile.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/players/profile/sb-profile.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default class RouteComponentsRiskManagementPlayersProfileSbProfileComponent extends Component {
  @service ajax;
  @service store;
  @service router;
  @service navigation;
  @service globalVars;
  @service currentUser;

  @tracked count;

  constructor() {
    super(...arguments);
    this.getPlayerDocumentCount.perform()
  }

  @(task(function* () {
    yield this.ajax.request('/get-player-document', {data: {player_id: this.args.model}}).then((data) => {
      this.count = data['player-documents']?data['player-documents'].length:0
    })
  }))getPlayerDocumentCount

  get menuItems() {
    return [
      {
        name: 'Stats & Profile',
        definer: 'r_m_players_key_stats_and_profile',
        linkTo: {
          path: 'risk-management.players.profile.stats-profile'
        }
      },
      {
        name: 'Actions',
        definer: 'r_m_players_key_actions',
        linkTo: {
          path: 'risk-management.players.profile.actions'
        }
      },
      {
        name: 'Bonuses',
        definer: 'r_m_players_key_bonuses',
        linkTo: {
          path: 'risk-management.players.profile.bonuses'
        }
      },
      {
        name: 'Casino Bet History',
        definer: 'r_m_players_key_bet_history',
        linkTo: {
          path: 'risk-management.players.profile.casino-bet-history',
        }
      },
      {
        name: 'Transactions',
        definer: 'r_m_players_key_transaction',
        linkTo: {
          path: 'risk-management.players.profile.transaction',
        }
      },
      {
        name: 'Messages',
        definer: 'r_m_players_key_messages',
        linkTo: {
          path: 'risk-management.players.profile.messages',
        }
      },
      {
        name: 'Password',
        definer: 'r_m_players_key_password',
        linkTo: {
          path: 'risk-management.players.profile.password',
        }
      },
      {
        name: 'Player Notes',
        definer: 'r_m_players_key_player_notes',
        linkTo: {
          path: 'risk-management.players.profile.notes'
        }
      }
    ];
  }
}
