import AuthenticatedRoute from '../../authenticated';

export default class BonusesBonusTemplatesCreateRoute extends AuthenticatedRoute {
  definer = 'bonuses_bonus_templates';
  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
