import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='main-info-container payment-settings-templates'>\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}} @select={{action this.selectConfig}} />\n  {{/if}}\n</div>\n\n{{yield}}", {"contents":"<div class='main-info-container payment-settings-templates'>\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}} @select={{action this.selectConfig}} />\n  {{/if}}\n</div>\n\n{{yield}}","moduleName":"backoffice/components/route-components/settings/max-bet/sb-max-bet.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/max-bet/sb-max-bet.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { A } from '@ember/array';
import { action } from '@ember/object';

export default class RouteComponentsSettingsMaxBetSbMaxBetComponent extends Component {
  @service partner;
  @service store;
  @service router;

  @tracked menuItems = A([]);

  constructor() {
    super(...arguments);
    this.getCurrencyGroups.perform().then((currencyGroups) => {
      currencyGroups.forEach((group) => {
        let obj = {};
        obj.name = group.desc;
        obj.definer = 'settings_max_bet_configuration';
        obj.linkTo = { path: 'settings.max-bet.setting', model: group.id };
        this.menuItems.pushObject(obj);
      });
    });
  }

  @task
  *getCurrencyGroups() {
    return yield this.store.query('max-bet-currency-group', {
      partner_id: this.partner.selected_id,
    });
  }

  @action
  selectConfig(newCurrencyGroupId) {
    this.router.transitionTo('settings.max-bet.setting', newCurrencyGroupId);
  }
}
