import AuthenticatedRoute from '../../authenticated';

export default class SettingsMaxBetIndexRoute extends AuthenticatedRoute {
  definer = 'settings_max_bet_configuration';

  queryParams = {
    partner_id: {
      refreshModel: true
    },
  };

  model(params) {
    return params;
  }
}
