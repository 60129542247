import Model, {attr, belongsTo,hasMany} from '@ember-data/model';
import {storageFor} from "ember-local-storage";
import { computed } from '@ember/object';

export default class DocumentModel extends Model {
  @storageFor('partner') partnerStorage

  @attr amount
  @attr amount_in_eur
  @attr currency_id
  @attr type_id
  @attr cashbox_id
  @attr player_id
  @attr db_type
  @attr account_type
  @attr created_at
  @attr updated_at
  @attr player_balance
  @attr player_balance_in_eur
  @attr operation_id
  @attr operation_title
  @attr reason
  @attr bonusPlayer
  @attr user
  @attr gateway_reference

  @belongsTo('document-type', { async: false, inverse: null }) documentType;
  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('user', { async: false, inverse: null }) manualActionUser;
  @hasMany('transaction', { async: false, inverse: null }) transactions;

  @computed('partnerStorage.isEurOn', 'amount', 'amount_in_eur')
  get amountProcessed() {
    return this.partnerStorage.get('isEurOn') ? this.amount_in_eur : this.amount;
  }

  @computed('partnerStorage.isEurOn', 'player_balance_in_eur', 'player_balance')
  get playerBalance() {
    return this.partnerStorage.get('isEurOn') ? this.player_balance_in_eur : this.player_balance;
  }
}
