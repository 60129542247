import Model, {attr, belongsTo, hasMany} from '@ember-data/model';

export default class PartnerModel extends Model {
  @attr title;
  @attr description;
  @attr('number') status;
  @attr('order', {defaultValue: 0}) order;
  @attr('number') group_id;
  @attr('boolean', {defaultValue: false}) checked;

  @hasMany('account', { async: false, inverse: null }) accounts;
  @hasMany('player', { async: false, inverse: null }) players;
  @hasMany('sports-kpi', { async: false, inverse: null }) sportsKpis;
  @hasMany('language', { async: false, inverse: null }) languages;
  @belongsTo('partner-config', { async: false, inverse: null }) partnerConfig;
  @belongsTo('mail-config', { async: false, inverse: null }) mailConfig;
  @belongsTo('partner-domain-manager', { async: false, inverse: null }) partnerDomainManager;
  @belongsTo('group', { async: false, inverse: null }) group;

  get blocked() { return this.status == 20 }

  get mainAccount() {
    let type = this.store.peekAll('account-type').filter(i => i.wallet === 'main').filter(i => i.owner === 'partner');
    return this.accounts.filter(i => i.type_id === Number(type[0].id))[0]
  }

  get mainAccounts() {
    let type = this.store.peekAll('account-type').filter(i => i.wallet === 'main').find(i => i.owner === 'partner');
    return this.accounts.filter(i => i.type_id === Number(type.id))
  }

  get currencies() {
    return this.mainAccounts.map(account => account.currency);
  }
}
