import AuthenticatedRoute from "../../../authenticated";

export default class SettingsMaxBetSettingIndexRoute extends AuthenticatedRoute {
  definer = 'settings_max_bet_configuration';

  model() {
    return this.paramsFor('settings/max-bet/setting').currency_group_id
  }

}

