import AuthenticatedRoute from "../../../authenticated";
import { inject as service } from '@ember/service';

export default class CasinoSettingsTemplateGamesRoute extends AuthenticatedRoute {
  @service store;

  definer = 'casino_settings_key_games';
  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    template_type_label_id: {
      refreshModel: true
    },
    template_category_id: {
      refreshModel: true
    },
    template_sub_category_id: {
      refreshModel: true
    },
    template_provider_id: {
      refreshModel: true
    },
    integration_id: {
      refreshModel: true
    },
    lastUpdatedStart: {
      refreshModel: true
    },
    lastUpdatedEnd: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    game_code: {
      refreshModel: true
    }
  };

  model(params) {
    this.store.unloadAll('casino-template-game-list');
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
