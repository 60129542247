import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';

export default class BonusesBonusTemplatesEditRoute extends AuthenticatedRoute {
  @service store;
  definer = 'bonuses_bonus_templates';

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  model(params) {
    return this.store.findRecord('bonus', params.bonus_id)
  }
}
