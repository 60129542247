import ApplicationAdapter from './application';

export default class PlayerAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.affiliate){
      return this.host + '/affiliate/client/players'
    }
    if(param.is_free_spin){
      return this.host + '/casino/free-spin/players'
    }
    return this.host + '/get-players'
  }

  urlForQueryRecord(param) {
    if(param.affiliate){
      return this.host + '/affiliate/client/player'
    }
    return this.host + '/get-player'
  }

  urlForUpdateRecord(id) {
    return this.host + `/edit-player/${id}`;
  }
}
