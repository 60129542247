import Service from '@ember/service';
import {tracked} from '@glimmer/tracking'
import moment from "moment";

export default class BonusServiceService extends Service {
  @tracked localIds = {};
  @tracked parentId = 0;

  defaultExpiration = moment().add(7, 'days').unix();

  typeSite = 10;
  typeAffiliate = 20;

  displayShow = 10;
  displayHidden = 20;

  wheelStatusActive = 'active';
  wheelStatusInactive = 'inactive';

  bonusStatusPending = 10;
  bonusStatusActive = 20;
  bonusStatusInactive = 10;
  bonusStatusComplete = 30;
  bonusStatusArchive = 40;

  bonusBenefitsTypeBonusAndFS = 10;
  bonusBenefitsTypeBonusMoney = 20;
  bonusBenefitsTypeFS = 30;
  bonusBenefitsTypeCash = 40;

  benefitsTypes = [
    {value: this.bonusBenefitsTypeBonusMoney, title: 'Bonus money'},
    {value: this.bonusBenefitsTypeFS, title: 'Free Spins'},
    {value: this.bonusBenefitsTypeBonusAndFS, title: 'Bonus & FS'},
    {value: this.bonusBenefitsTypeCash, title: 'Cash'}
  ]

  statuses = [
    {id: this.bonusStatusPending, title: 'Pending'},
    {id: this.bonusStatusInactive, title: 'Inactive'},
    {id: this.bonusStatusActive, title: 'Active'},
    {id: this.bonusStatusComplete, title: 'Complete'},
    {id: this.bonusStatusArchive, title: 'Archive'}
  ]

  currencyCourse = 8;
  weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((v, i) => ({
    id: i + 1,
    title: v
  }));

  actionDepositId = 4;
  actionRegistrationId = 2;
  actionLoginId = 1;
  actionBirthdayId = 3;
  actionNoActionId = 9;
  actionManualId = 42;

  actionDepositLabel = 'Deposit';
  actionRegistrationLabel = 'Registration';
  actionLoginLabel = 'Login';
  actionBirthdayLabel = 'Birthday';
  actionNoActionLabel = 'No Action';
  actionManualLabel = 'Manual';

  actions = [
    {id: this.actionDepositId, title: this.actionDepositLabel},
    {id: this.actionRegistrationId, title: this.actionRegistrationLabel},
    {id: this.actionLoginId, title: this.actionLoginLabel},
    {id: this.actionManualId, title: this.actionManualLabel}
  ]

  wheelBonusMoneyType = 'money'
  wheelBonusTypeBonus = 'bonus'
  wheelBonusCoinType = 'coin'
  wheelBonusNoneType = 'none'

  get benefitsTypesWithAll() {
    return [
      {
        value: 'all',
        title: 'All'
      },
      ...this.benefitsTypes
    ]
  }

  removeIds(logicId) {
    delete this.localIds[logicId]
  }

  isDisplayedByCode(code) {
    if (code === this.displayShow) {
      return true
    }

    if (code === this.displayHidden) {
      return false
    }

    console.error(`No such code (${code}) in bonusService.isDisplayedByCode`)
    return null
  }

  isActiveByCode(code) {
    if (code === this.bonusStatusActive) {
      return true
    }

    if (code === this.bonusStatusInactive) {
      return false
    }

    console.error(`No such code (${code}) in bonusService.isActiveByCode`)
    return null
  }

  setIds(logicId, id, type) {
    if (!this.localIds[logicId]) {
      this.localIds[logicId] = {params: {}}
    }
    if (id) {
      switch (parseInt(type)) {
        case this.sportsbook.sport:
          this.localIds[logicId].params.sport_id = id;
          break;
        case this.sportsbook.league:
          this.localIds[logicId].params.league_id = id;
          break;
        case this.sportsbook.game:
          this.localIds[logicId].params.game_id = id;
          break;
        case this.sportsbook.market:
          this.localIds[logicId].params.market_id = id;
          break;
        case this.casino.category:
          this.localIds[logicId].params.category_id = id;
          break;
        case this.casino.provider:
          this.localIds[logicId].params.provider_id = id;
          break;
        case this.casino.game:
          this.localIds[logicId].params.game_id = id;
          break;
      }
    }
  }

}
