import AuthenticatedRoute from '../../authenticated';
import { inject as service } from '@ember/service';

export default class SettingsMaxBetSettingRoute extends AuthenticatedRoute {
  @service store;
  @service partner;

  definer = 'settings_max_bet_configuration';

  model(params) {
    return this.store.queryRecord('max-bet-currency-group', {
      currency_group_id: params.currency_group_id,
      partner_id: this.partner.selected_id,
    });
  }

  activate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = false;
  }
}
