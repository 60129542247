import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class={{this.className}}\n  {{on 'click' this.handleClick}}\n  disabled={{this.isExporting}}\n  type=\"button\"\n>\n{{@text}}\n</button>\n", {"contents":"<button\n  class={{this.className}}\n  {{on 'click' this.handleClick}}\n  disabled={{this.isExporting}}\n  type=\"button\"\n>\n{{@text}}\n</button>\n","moduleName":"backoffice/components/generic-components/form-inputs/export-csv-button/sb-export-csv-button.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/form-inputs/export-csv-button/sb-export-csv-button.hbs"}});
import Component from "@glimmer/component";
import {tracked} from "@glimmer/tracking";
import {action} from "@ember/object";
import {inject as service} from "@ember/service";
import {task} from "ember-concurrency";
import moment from "moment";
import downloadFile from "../../../../utils/download-file";

export default class GenericComponentsFormInputsExportCsvButtonSbExportCsvButton extends Component {
  @service ajax;
  @service notifications;

  @tracked fileName = 'exports.csv';
  @tracked endpoint;

  constructor() {
    super(...arguments);
    this.fileName = this.args.fileName || this.fileName;
    this.endpoint = this.args.endpoint
  }

  get className() {
    const loadingClass = this.isRunning ? 'loading' : '';
    return `btn ${this.args.className} ${loadingClass}`;
  }

  get filters() {
    return {...this.args.filters}
  }

  get isRunning() {
    return this.exportFileTask.isRunning;
  }

  @action
  handleClick() {
    this.exportFileTask.perform();
  }

  @task
  *exportFileTask() {
    const errorMessage = "Error exporting CSV";

    try {
      const response = yield this.ajax.requestRawText(this.endpoint, {
        method: 'GET',
        data: this.filters,
      });

      if (response.textStatus !== 'success') {
        this.notifications.error(errorMessage, {autoClear: true});
      }

      const fileName = `${moment().format("YYYYMMDD_hhmmss")}-${this.fileName}`;
      let blob = new Blob([response.payload], {type: 'text/csv'});
      downloadFile(blob, fileName);
    } catch (e) {
      const message = ' ' + (e?.message || e?.payload?.errors[0] || 'unknown');
      this.notifications.error(errorMessage + message, {autoClear: true});
    }
  }
}
