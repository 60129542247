import Component from '@glimmer/component';
import {action, set} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {didCancel, task} from 'ember-concurrency';

export default class MainFilter extends Component {
  @service router;
  @service partner;
  @service ajax;
  @service store;
  @service currentUser;
  @service media;

  pageAttr = 'page';
  perPageAttr = 'perPage';

  @tracked timeout;
  @tracked expandedData;
  @tracked isExpanded = 0;

  SORT_ASC = 4;
  SORT_DESC = 3;

  catchCallback = (e) => {
    if (!didCancel) {
      throw e;
    }
  };

  @action
  sortBy(name) {
    set(this.filters, 'sort_name', name);
    set(this.filters, 'sort_type', this.filters.sort_type === this.SORT_ASC ? this.SORT_DESC : this.SORT_ASC);
    this.setTransition();
    this.baseTask.perform().catch(this.catchCallback);
  }

  setTimeOut() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.setPage(1);
    }, 300)
  }

  @action
  setPerPage(val) {
    set(this, `filters.${this.perPageAttr}`, val);
    this.setPage(1)
  }

  @action
  applyCalendar(event, dateRangePicker) {
    set(this.filters, 'start', dateRangePicker.startDate.unix());
    set(this.filters, 'end', dateRangePicker.endDate.unix());
    this.setPage(1);
  }

  setTransition() {
    this.router.transitionTo(this.baseRoute || this.router.currentRoute.name, {
      queryParams: this.filters
    })
  }

  @action
  setPage(page) {
    this.filters[this.pageAttr] = page;
    this.setTransition();
    return this.baseTask.perform().catch(this.catchCallback);
  }
  @action
  toggleReports(e) {
    let element = e.target.closest(".table-content");
    let childElement = element.querySelector(".table-body");

    if (childElement.style.maxHeight) {
      childElement.style.maxHeight = null;
    } else {
      childElement.style.maxHeight = `680px`;
    }

    // element.classList.toggle("show");

    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.expandedData = {};
      this.expandedDataTask.perform()
    }
  }

  @(task(function* () {
    const data = {
      ...this.filters,
      isExpanded: this.isExpanded,
    }
    yield this.ajax.request(`/get-stats-ggr`, {data: data}).then((data) => {
      this.expandedData = data['player-kpis']
      this.expandedData.meta = data.meta;
    }).catch((payload) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))expandedDataTask
}
