import Helper from '@ember/component/helper';
import {inject as service} from '@ember/service'

export default class Can extends Helper {
  @service currentUser;

  compute([definer]) {
    if(definer === 'settings_max_bet_configuration'){
      return
    }
    return this.currentUser.roleDefined(definer)
  }
}
